
import React from 'react';
import { Book, Heart, Globe, Users } from 'lucide-react';

const Features = () => {
  const features = [
    {
      icon: <Book className="h-10 w-10 text-primary" />,
      title: "Education",
      description: "Providing access to quality education and vocational training to underprivileged communities."
    },
    {
      icon: <Heart className="h-10 w-10 text-primary" />,
      title: "Healthcare",
      description: "Supporting healthcare initiatives to ensure medical services reach those who need them most."
    },
    {
      icon: <Globe className="h-10 w-10 text-primary" />,
      title: "Environment",
      description: "Promoting sustainable practices and environmental conservation for a better future."
    },
    {
      icon: <Users className="h-10 w-10 text-primary" />,
      title: "Community Building",
      description: "Fostering strong community relationships and empowering local leaders."
    }
  ];

  return (
    <section className="section bg-white">
      <div className="container mx-auto">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="heading-2">Our <span className="text-primary">Focus Areas</span></h2>
          <p className="subheading">
            We believe in a holistic approach to community development, focusing on these key areas to create sustainable impact.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="bg-white p-6 rounded-xl shadow-md border border-gray-100 card-hover"
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className="mb-4 p-3 bg-primary/10 inline-block rounded-lg">
                {feature.icon}
              </div>
              <h3 className="text-xl font-bold mb-3">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
