
import React from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

const TermsPage = () => {
  return (
    <div className="min-h-screen">
      <Navigation />
      
      <section className="pt-32 pb-12 bg-gray-100">
        <div className="container mx-auto">
          <div className="max-w-3xl">
            <h1 className="heading-2">Terms of Service</h1>
            <p className="text-gray-600 text-lg mt-4">
              Last updated: {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
            </p>
          </div>
        </div>
      </section>
      
      <section className="py-16">
        <div className="container mx-auto">
          <div className="max-w-4xl mx-auto prose prose-lg">
            <p>
              Please read these Terms of Service ("Terms") carefully before using the GSR Foundation website and services.
            </p>
            
            <h2>Acceptance of Terms</h2>
            <p>
              By accessing or using our website, donating to our foundation, or participating in our programs, you agree to be bound by these Terms and our Privacy Policy.
            </p>
            
            <h2>Use of Our Website</h2>
            <p>
              The GSR Foundation website and its content are intended solely for your personal, non-commercial use. You may not modify, reproduce, distribute, create derivative works from, or publicly display our content without our prior written consent.
            </p>
            
            <h2>Donations</h2>
            <p>
              All donations to GSR Foundation are voluntary and non-refundable. We will use reasonable efforts to ensure that donations are used in accordance with the donor's intentions and our stated mission. Tax receipts will be provided for eligible donations as required by law.
            </p>
            
            <h2>Intellectual Property</h2>
            <p>
              The GSR Foundation name, logo, website, and all content, features, and functionality are owned by GSR Foundation and are protected by copyright, trademark, and other intellectual property laws.
            </p>
            
            <h2>User Submissions</h2>
            <p>
              Any material, information, or idea you transmit to or post on our website will be treated as non-confidential and non-proprietary. We may use such submissions for any purpose, including but not limited to reproduction, disclosure, transmission, publication, and posting.
            </p>
            
            <h2>Limitation of Liability</h2>
            <p>
              GSR Foundation and its officers, directors, employees, and agents will not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of your access to or use of our website or services.
            </p>
            
            <h2>Indemnification</h2>
            <p>
              You agree to defend, indemnify, and hold harmless GSR Foundation, its officers, directors, employees, and agents from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees arising out of your use of our website or services or your violation of these Terms.
            </p>
            
            <h2>Changes to These Terms</h2>
            <p>
              We may update these Terms from time to time. If we make changes, we will notify you by revising the date at the top of these Terms. Continued use of our website after any such changes constitutes your acceptance of the new Terms.
            </p>
            
            <h2>Governing Law</h2>
            <p>
              These Terms and your use of our website and services will be governed by and construed in accordance with the laws of the state of California, without regard to its conflict of law provisions.
            </p>
            
            <h2>Contact Us</h2>
            <p>
              If you have any questions about these Terms, please contact us at:
            </p>
            <address>
              GSR Foundation<br />
              123 Foundation Way<br />
              San Francisco, CA 94103<br />
              legal@gsrfoundation.org<br />
              (555) 123-4567
            </address>
          </div>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default TermsPage;
