import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause, Volume2, VolumeX, Maximize, X, SkipForward, SkipBack } from 'lucide-react';
import { cn } from '../lib/utils';
import { motion } from 'framer-motion';
import { Button } from './ui/button';
import { Slider } from './ui/slider';

interface VideoPlayerProps {
  src: string;
  poster?: string;
  className?: string;
  autoplay?: boolean;
  controls?: boolean;
  loop?: boolean;
  muted?: boolean;
  onClose?: () => void;
  showCloseButton?: boolean;
}

const VideoPlayer = ({
  src,
  poster,
  className,
  autoplay = false,
  controls = true,
  loop = false,
  muted = false,
  onClose,
  showCloseButton = false
}: VideoPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(autoplay);
  const [isMuted, setIsMuted] = useState(muted);
  const [isControlsVisible, setIsControlsVisible] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isBuffering, setIsBuffering] = useState(false);
  
  // Set duration when metadata is loaded
  useEffect(() => {
    const handleLoadedMetadata = () => {
      if (videoRef.current) {
        setDuration(videoRef.current.duration);
      }
    };

    const handleTimeUpdate = () => {
      if (videoRef.current) {
        setCurrentTime(videoRef.current.currentTime);
      }
    };

    const handleWaiting = () => {
      setIsBuffering(true);
    };

    const handlePlaying = () => {
      setIsBuffering(false);
    };
    
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
      videoElement.addEventListener('waiting', handleWaiting);
      videoElement.addEventListener('playing', handlePlaying);
    }
    
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
        videoElement.removeEventListener('waiting', handleWaiting);
        videoElement.removeEventListener('playing', handlePlaying);
      }
    };
  }, []);
  
  // Hide controls after inactivity
  useEffect(() => {
    if (!controls) return;
    
    let timeout: NodeJS.Timeout;
    
    const resetTimeout = () => {
      clearTimeout(timeout);
      setIsControlsVisible(true);
      
      timeout = setTimeout(() => {
        if (isPlaying) {
          setIsControlsVisible(false);
        }
      }, 3000);
    };
    
    resetTimeout();
    
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('mousemove', resetTimeout);
      videoElement.addEventListener('touchstart', resetTimeout);
    }
    
    return () => {
      clearTimeout(timeout);
      if (videoElement) {
        videoElement.removeEventListener('mousemove', resetTimeout);
        videoElement.removeEventListener('touchstart', resetTimeout);
      }
    };
  }, [controls, isPlaying]);
  
  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  
  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };
  
  const handleEnterFullScreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      }
    }
  };
  
  const handleVideoEnded = () => {
    setIsPlaying(false);
    setIsControlsVisible(true);
    if (loop && videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };
  
  const handleSeek = (value: number[]) => {
    if (videoRef.current) {
      const newTime = value[0];
      videoRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };
  
  const handleSkipForward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = Math.min(videoRef.current.currentTime + 10, duration);
    }
  };
  
  const handleSkipBackward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = Math.max(videoRef.current.currentTime - 10, 0);
    }
  };
  
  // Format time in MM:SS
  const formatTime = (seconds: number): string => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };
  
  return (
    <motion.div 
      className={cn("relative rounded-lg overflow-hidden bg-black group", className)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {isBuffering && (
        <div className="absolute inset-0 flex items-center justify-center z-10 bg-black/30">
          <div className="w-12 h-12 rounded-full border-4 border-primary border-t-transparent animate-spin"></div>
        </div>
      )}
      
      <video
        ref={videoRef}
        src={src}
        poster={poster}
        autoPlay={autoplay}
        loop={loop}
        muted={muted}
        playsInline
        onClick={togglePlay}
        onEnded={handleVideoEnded}
        className="w-full h-full object-contain"
      />
      
      {!isPlaying && !isBuffering && (
        <div className="absolute inset-0 flex items-center justify-center z-10">
          <Button
            variant="ghost"
            size="icon"
            className="h-16 w-16 rounded-full bg-primary/30 text-white hover:bg-primary/50 hover:scale-110 transition-all"
            onClick={togglePlay}
          >
            <Play className="h-8 w-8 ml-1" />
          </Button>
        </div>
      )}
      
      {controls && (
        <motion.div 
          className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/90 to-transparent p-3"
          initial={{ opacity: 1 }}
          animate={{ opacity: isControlsVisible || !isPlaying ? 1 : 0 }}
          transition={{ duration: 0.2 }}
        >
          {duration > 0 && (
            <div className="space-y-2 px-1">
              <Slider
                value={[currentTime]}
                max={duration}
                step={0.01}
                onValueChange={handleSeek}
                className="cursor-pointer"
              />
              <div className="flex justify-between text-xs text-white/80">
                <span>{formatTime(currentTime)}</span>
                <span>{formatTime(duration)}</span>
              </div>
            </div>
          )}
          
          <div className="flex items-center justify-between">
            <div className="flex gap-1">
              <Button 
                variant="ghost" 
                size="icon"
                className="h-8 w-8 rounded-full bg-white/10 text-white hover:bg-white/20"
                onClick={handleSkipBackward}
              >
                <SkipBack className="h-4 w-4" />
              </Button>
              
              <Button 
                variant="ghost" 
                size="icon"
                className="h-8 w-8 rounded-full bg-white/10 text-white hover:bg-white/20"
                onClick={togglePlay}
              >
                {isPlaying ? (
                  <Pause className="h-4 w-4" />
                ) : (
                  <Play className="h-4 w-4" />
                )}
              </Button>
              
              <Button 
                variant="ghost" 
                size="icon"
                className="h-8 w-8 rounded-full bg-white/10 text-white hover:bg-white/20"
                onClick={handleSkipForward}
              >
                <SkipForward className="h-4 w-4" />
              </Button>
            </div>
            
            <div className="flex gap-1">
              <Button 
                variant="ghost" 
                size="icon"
                className="h-8 w-8 rounded-full bg-white/10 text-white hover:bg-white/20"
                onClick={toggleMute}
              >
                {isMuted ? (
                  <VolumeX className="h-4 w-4" />
                ) : (
                  <Volume2 className="h-4 w-4" />
                )}
              </Button>
              
              <Button 
                variant="ghost" 
                size="icon"
                className="h-8 w-8 rounded-full bg-white/10 text-white hover:bg-white/20"
                onClick={handleEnterFullScreen}
              >
                <Maximize className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </motion.div>
      )}
      
      {showCloseButton && onClose && (
        <Button
          variant="ghost"
          size="icon"
          className="absolute top-2 right-2 h-8 w-8 rounded-full bg-black/50 text-white hover:bg-black/70 z-10"
          onClick={onClose}
        >
          <X className="h-4 w-4" />
        </Button>
      )}
    </motion.div>
  );
};

export default VideoPlayer;