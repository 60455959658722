
import React from 'react';
import { Quote } from 'lucide-react';

const Testimonials = () => {
  const testimonials = [
    {
      quote: "The educational program provided by GSR Foundation, which distributed materials for the NTSE exam, has been incredibly helpful for us in preparing for the exam and allocating additional content.",
      author: "MSM School Teacher",
      role: "Student",
      image: "bg-gray-300"
    },
    {
      quote: "We would like to express our heartfelt gratitude to the GSR Foundation for their generous initiative in awarding cash prizes to our 10th class toppers. This recognition not only celebrates their hard work and dedication but also motivates all our students to strive for excellence in their academic pursuits. The support from the GSR Foundation plays a significant role in encouraging our bright young minds to continue to excel and achieve their dreams.",
      author: "School Students",
      role: "Student",
      image: "bg-gray-300"
    },
    {
      quote: "Thanks to the community outreach program, we were able to distribute food to children and elderly individuals, providing essential support and nourishment to those in need.",
      author: "Githamayee Team",
      role: "Program Lead",
      image: "bg-gray-300"
    }
  ];

  return (
    <section className="section bg-primary text-white">
      <div className="container mx-auto">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="heading-2">Voices of <span className="text-white">Impact</span></h2>
          <p className="text-white mt-4 max-w-3xl">
            Real stories from people whose lives have been transformed through our programs and initiatives.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index}
              className="bg-primary-light/10 backdrop-blur-sm rounded-xl p-6 border border-white/30"
            >
              <Quote className="h-8 w-8 text-white/40 mb-4" />
              <p className="text-white/90 italic mb-6">{testimonial.quote}</p>
              <div className="flex items-center">
                <div className={`w-12 h-12 rounded-full overflow-hidden ${testimonial.image} mr-4`}></div>
                <div>
                  <p className="font-semibold text-white">{testimonial.author}</p>
                  <p className="text-white/70 text-sm">{testimonial.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
