import React, { useEffect } from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import { CheckCircle, ChevronDown } from 'lucide-react';
import { Button } from '../components/ui/button';
import FileDownload from '../components/FileDownload';

const InfoPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  const annualReports = [
    { year: "2023", fileName: "GSR_Annual_Report_2023.pdf", fileSize: "4.2 MB", url: "/downloads/GSR_Annual_Report_2023.pdf" },
    { year: "2022", fileName: "GSR_Annual_Report_2022.pdf", fileSize: "3.8 MB", url: "/downloads/GSR_Annual_Report_2022.pdf" },
    { year: "2021", fileName: "GSR_Annual_Report_2021.pdf", fileSize: "3.5 MB", url: "/downloads/GSR_Annual_Report_2021.pdf" }
  ];

  const quickDownloads = [
    { name: "GSR Foundation Brochure", fileName: "GSR_Brochure.pdf", url: "/downloads/GSR_Brochure.pdf" },
    { name: "Donation Form PDF", fileName: "Donation_Form.pdf", url: "/downloads/Donation_Form.pdf" },
    { name: "Volunteer Application", fileName: "Volunteer_Application.pdf", url: "/downloads/Volunteer_Application.pdf" },
    { name: "Program Summary", fileName: "Program_Summary.pdf", url: "/downloads/Program_Summary.pdf" }
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <Navigation />
      
      <section className="pt-32 pb-12 bg-primary text-white">
        <div className="container mx-auto">
          <div className="max-w-3xl">
            <motion.h1 
              className="heading-1"
              initial="hidden"
              animate="visible"
              variants={fadeIn}
            >
              Information Resources
            </motion.h1>
            <motion.p 
              className="text-white text-lg mt-4"
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              transition={{ delay: 0.2 }}
            >
              Access valuable resources, reports, and information about our foundation's work and impact.
            </motion.p>
          </div>
        </div>
      </section>
      
      <section className="py-16 bg-white">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <motion.div 
              className="lg:col-span-2 space-y-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div>
                <h2 className="heading-3 mb-6">Annual Reports</h2>
                <div className="space-y-4">
                  {annualReports.map((report, index) => (
                    <FileDownload
                      key={index}
                      fileName={report.fileName}
                      fileUrl={report.url}
                      fileSize={report.fileSize}
                      fileType="pdf"
                      displayName={`Annual Report ${report.year}`}
                    />
                  ))}
                </div>
              </div>
              
              <div>
                <h2 className="heading-3 mb-6">Impact Studies</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {[
                    { 
                      title: "Education Program Outcomes", 
                      description: "Results from our 5-year educational initiatives across rural communities.",
                      image: "https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                    },
                    { 
                      title: "Healthcare Access Study", 
                      description: "Analysis of improved healthcare accessibility in underserved regions.",
                      image: "https://images.unsplash.com/photo-1576091160550-2173dba999ef?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" 
                    },
                    { 
                      title: "Environmental Sustainability Report", 
                      description: "Impact of our conservation efforts on local ecosystems.",
                      image: "https://images.unsplash.com/photo-1534516494880-46ebb2a9eeae?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" 
                    },
                    { 
                      title: "Community Development Analysis", 
                      description: "Long-term effects of infrastructure improvements in partner communities.",
                      image: "https://images.unsplash.com/photo-1584521764582-98f47b00d0af?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                    }
                  ].map((study, index) => (
                    <motion.div 
                      key={index}
                      className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-lg transition-shadow"
                      whileHover={{ y: -5 }}
                    >
                      <div className="h-40 overflow-hidden">
                        <img 
                          src={study.image} 
                          alt={study.title} 
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="p-4">
                        <h3 className="font-bold text-lg mb-2">{study.title}</h3>
                        <p className="text-gray-600 text-sm">{study.description}</p>
                        <Button variant="link" className="mt-2 text-primary p-0">
                          Read Study →
                        </Button>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
            
            <motion.div
              className="bg-gray-50 p-6 rounded-xl"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <h2 className="text-xl font-bold mb-6">Resources</h2>
              
              <div className="space-y-6">
                <div>
                  <h3 className="text-lg font-semibold mb-3">Frequently Asked Questions</h3>
                  <div className="space-y-3">
                    {[
                      { 
                        question: "How is my donation used?", 
                        answer: "Your donation directly supports our programs in education, healthcare, environmental conservation, and community development with at least 85% going to direct program implementation." 
                      },
                      { 
                        question: "Can I volunteer with GSR Foundation?", 
                        answer: "Yes! We welcome volunteers for both local and international opportunities. Please use our contact form to connect with our volunteer coordinator." 
                      },
                      { 
                        question: "Does GSR Foundation provide tax receipts?",
                        answer: "Yes, GSR Foundation is a registered non-profit and all donations are tax-deductible. Tax receipts are provided for all donations." 
                      }
                    ].map((faq, index) => (
                      <motion.details 
                        key={index} 
                        className="bg-white p-4 rounded-lg border border-gray-200"
                        whileHover={{ backgroundColor: "#f9fafb" }}
                      >
                        <summary className="font-medium flex justify-between items-center cursor-pointer">
                          {faq.question}
                          <ChevronDown size={18} className="transform transition-transform" />
                        </summary>
                        <p className="mt-2 text-gray-600 text-sm">{faq.answer}</p>
                      </motion.details>
                    ))}
                  </div>
                </div>
                
                <div>
                  <h3 className="text-lg font-semibold mb-3">Quick Downloads</h3>
                  <ul className="space-y-2">
                    {quickDownloads.map((item, index) => (
                      <li key={index}>
                        <FileDownload
                          fileName={item.fileName}
                          fileUrl={item.url}
                          fileType="pdf"
                          variant="link"
                          className="p-0"
                          displayName={item.name}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                
                <div className="bg-primary/10 p-4 rounded-lg">
                  <h3 className="text-lg font-semibold mb-2">Contact for Information</h3>
                  <p className="text-gray-600 text-sm mb-3">Need specific information not found here?</p>
                  <Button className="w-full bg-primary text-white">Request Information</Button>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto">
          <motion.div 
            className="text-center max-w-3xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="heading-3 mb-4">Our Transparency Commitment</h2>
            <p className="text-gray-600 mb-8">
              GSR Foundation is committed to complete transparency in our operations, finances, and impact.
              We believe our donors, partners, and the communities we serve deserve full visibility into our work.
            </p>
            
            <div className="flex flex-wrap justify-center gap-4">
              {[
                "Financial Accountability", 
                "Impact Measurement", 
                "Ethical Practices", 
                "Program Evaluation"
              ].map((item, index) => (
                <motion.div
                  key={index}
                  className="bg-white py-2 px-4 rounded-full flex items-center gap-2 shadow-sm"
                  whileHover={{ scale: 1.05 }}
                >
                  <CheckCircle size={18} className="text-primary" />
                  <span>{item}</span>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default InfoPage;
