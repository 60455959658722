
import React from 'react';
import Navigation from '../components/Navigation';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

const ContactPage = () => {
  return (
    <div className="min-h-screen">
      <Navigation />
      
      <section className="pt-32 pb-12 bg-primary text-white">
        <div className="container mx-auto">
          <div className="max-w-3xl">
            <h1 className="heading-1">Contact Us</h1>
            <p className="text-white text-lg mt-4">
              We'd love to hear from you. Reach out with questions about our programs or how you can get involved with GSR Foundation.
            </p>
          </div>
        </div>
      </section>
      
      <Contact />
      
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto">
          <div className="text-center max-w-3xl mx-auto">
            <h2 className="heading-3">Frequently Asked Questions</h2>
            <p className="text-gray-600 mt-4">
              Find answers to common questions about GSR Foundation and our work.
            </p>
          </div>
          
          <div className="max-w-3xl mx-auto mt-12 space-y-6">
            {[
              {
                question: "How can I donate to GSR Foundation?",
                answer: "You can donate through our website, by mail, or by contacting our development office directly. We accept various payment methods including credit cards, bank transfers, and checks."
              },
              {
                question: "How is my donation used?",
                answer: "Your donation directly supports our programs in education, healthcare, environmental conservation, and community development. We ensure that at least 85% of all donations go directly to program implementation."
              },
              {
                question: "Can I volunteer with GSR Foundation?",
                answer: "Yes, we welcome volunteers! Please contact our volunteer coordinator through the contact form to learn about current opportunities both locally and internationally."
              },
              {
                question: "Does GSR Foundation provide tax receipts?",
                answer: "Yes, GSR Foundation is a registered non-profit organization and all donations are tax-deductible. We provide tax receipts for all donations."
              }
            ].map((faq, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                <h3 className="text-lg font-semibold mb-2">{faq.question}</h3>
                <p className="text-gray-600">{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default ContactPage;
