
import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { Button } from "../components/ui/button";
import { ArrowLeft } from "lucide-react";

const NotFound = () => {
  const location = useLocation();

  useEffect(() => {
    console.error(
      "404 Error: User attempted to access non-existent route:",
      location.pathname
    );
  }, [location.pathname]);

  return (
    <div className="min-h-screen flex flex-col">
      <Navigation />
      
      <div className="flex-grow flex items-center justify-center bg-gray-50">
        <div className="container px-4 py-16 text-center">
          <div className="max-w-lg mx-auto">
            <h1 className="text-9xl font-bold text-primary">404</h1>
            <h2 className="text-3xl font-bold mt-6 mb-4">Page Not Found</h2>
            <p className="text-gray-600 mb-8">
              We're sorry, the page you requested could not be found. Please check the URL or navigate back to the homepage.
            </p>
            <Link to="/">
              <Button className="btn-primary flex items-center gap-2 mx-auto">
                <ArrowLeft className="h-4 w-4" />
                Return to Home
              </Button>
            </Link>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default NotFound;
