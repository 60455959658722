
import React from 'react';
import { Button } from './ui/button';

import image3 from "../assets/images/FoodDistribution2.jpeg"

const Hero = () => {
  return (
    <section className="relative min-h-screen flex items-center bg-gradient-to-br from-gray-50 to-gray-100 overflow-hidden">
      {/* Background decorative elements */}
      <div className="absolute inset-0 z-0 overflow-hidden">
        <div className="absolute -top-32 -right-32 w-64 h-64 rounded-full bg-primary/10 animate-pulse"></div>
        <div className="absolute top-1/4 -left-12 w-48 h-48 rounded-full bg-primary/5"></div>
        <div className="absolute bottom-12 right-1/4 w-72 h-72 rounded-full bg-primary/5"></div>
      </div>
      
      <div className="container mx-auto px-4 md:px-6 relative z-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div className="space-y-6 animate-fade-in">
            <h1 className="heading-1">
              <span className="text-primary">Empowering</span> Communities Through Sustainable Change
            </h1>
            <p className="subheading">
              GSR Foundation works to create lasting impact through education, health, and environmental initiatives that transform lives and build stronger communities.
            </p>
            <div className="flex flex-wrap gap-4 pt-4">
              <Button className="btn-primary text-white">Learn More</Button>
              <Button variant="outline" className="btn-outline">Our Programs</Button>
            </div>
          </div>
          <div className="relative animate-zoom-in">
            <div className="aspect-[16/9] rounded-xl overflow-hidden shadow-2xl border-8 border-white transform rotate-2">
              <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                <div className="text-gray-500 text-center p-2">
                  <img src={image3} />
                </div>
              </div>
            </div>
            <div className="absolute -bottom-6 -left-6 bg-white rounded-lg p-4 shadow-lg transform -rotate-3">
              <p className="text-primary font-bold">1.5+ Years</p>
              <p className="text-sm text-gray-600">Of Community Service</p>
            </div>
            <div className="absolute -top-6 -right-6 bg-white rounded-lg p-4 shadow-lg transform rotate-6">
              <p className="text-primary font-bold">1,000+</p>
              <p className="text-sm text-gray-600">Lives Impacted</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
