
import React, { useEffect } from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import { Check, Users, Award, Heart, Target, BarChart3 } from 'lucide-react';
import sagar from "../assets/images/sagar.jpg";
import sunny from "../assets/images/sunny.jpeg";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const team = [
    {
      name: "Guvvala Sagar Reddy",
      role: "Chairman",
      image: sagar,
      bio: "Sagar has over 1.5 years of experience in nonprofit leadership and community development, demonstrating a strong commitment to fostering sustainable change."
    },
    {
      name: "Sunny Mohammed",
      role: "Vice-Chairman",
      image: sunny,
      bio: "Sunny oversees all foundation programs and ensures they create meaningful impact through evidence-based approaches."
    },
    // {
    //   name: "Jennifer Cruz",
    //   role: "Development Director",
    //   image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    //   bio: "Jennifer leads fundraising efforts and builds relationships with donors and partners to support our mission globally."
    // },
    // {
    //   name: "David Thompson",
    //   role: "Communications Director",
    //   image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    //   bio: "David manages all external communications and public relations for the foundation with a focus on storytelling."
    // }
  ];

  const milestones = [
    {
      year: "2023",
      title: "First Program Launched",
      description: "Launched the Toppers Program, providing Cash Prizes to 3 Topper students in MSM AC Nagar High School, Nellore, Andhra Pradesh"
    },
    {
      year: "2023",
      title: "Second Program Books Distribution",
      description: "GSR Foundation was hepful for the students who are goign through the NTSE Scholarship exam that is going to happen in 2024 , provide materials for preparation for 30+ students."
    },
    {
      year: "2024",
      title: "Food Distribution Program",
      description: "Made Food dsitribution for the poor and needy students in and also old age people in Gitamayee Ashramam, Nellore, Andhra Pradesh."
    },
    // {
    //   year: "2015",
    //   title: "Expanded to 5 Countries",
    //   description: "Extended operations to reach communities across multiple countries in Asia and Africa."
    // },
    // {
    //   year: "2018",
    //   title: "Clean Water Initiative Started",
    //   description: "Began providing clean water solutions to rural communities, benefiting over 25,000 people."
    // },
    // {
    //   year: "2020",
    //   title: "Reached 10,000 Beneficiaries",
    //   description: "Milestone of directly impacting 10,000 lives through our educational and healthcare programs."
    // },
    // {
    //   year: "2023",
    //   title: "New Headquarters",
    //   description: "Opened new sustainable headquarters to support growing operations and environmental commitment."
    // }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 }
  };

  return (
    <div className="min-h-screen">
      <Navigation />

      <section className="pt-32 pb-12 bg-primary text-white">
        <div className="container mx-auto">
          <div className="max-w-3xl">
            <motion.h1
              className="heading-1"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              About Us
            </motion.h1>
            <motion.p
              className="text-white text-lg mt-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.5 }}
            >
              Learn about our mission, vision, and the dedicated team behind GSR Foundation's work to create lasting positive change in communities worldwide.
            </motion.p>
          </div>
        </div>
      </section>

      <section className="py-16 bg-white">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <motion.div
              className="space-y-6"
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <div>
                <h2 className="heading-2">Our <span className="text-primary">Mission</span></h2>
                <p className="text-gray-700 text-lg mt-4">
                  GSR Foundation exists to empower communities through sustainable change initiatives that address critical challenges in education, healthcare, environment, and community development. We believe in creating long-lasting solutions that transform lives and build resilient communities.
                </p>
              </div>

              <div className="pt-8">
                <h2 className="heading-2">Our <span className="text-primary">Vision</span></h2>
                <p className="text-gray-700 text-lg mt-4">
                  We envision a world where every community has the resources, knowledge, and support needed to thrive and build a sustainable future for generations to come. We strive for a future where equity, education, and opportunity are available to all.
                </p>
              </div>

              <motion.div
                className="pt-8"
                variants={containerVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
              >
                <h3 className="text-xl font-bold mb-6">Our Core Values</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {[
                    {
                      value: "Integrity",
                      icon: <Award className="h-5 w-5 text-primary" />,
                      description: "We adhere to the highest ethical standards in all we do."
                    },
                    {
                      value: "Sustainability",
                      icon: <Target className="h-5 w-5 text-primary" />,
                      description: "We design programs with long-term positive impact in mind."
                    },
                    {
                      value: "Empowerment",
                      icon: <Heart className="h-5 w-5 text-primary" />,
                      description: "We build capacity within communities to lead their own development."
                    },
                    {
                      value: "Collaboration",
                      icon: <Users className="h-5 w-5 text-primary" />,
                      description: "We partner with local and global organizations for greater impact."
                    },
                    {
                      value: "Innovation",
                      icon: <BarChart3 className="h-5 w-5 text-primary" />,
                      description: "We embrace creative solutions to complex challenges."
                    }
                  ].map((item, index) => (
                    <motion.div
                      key={index}
                      variants={itemVariants}
                      className="flex items-start space-x-3 bg-gray-50 p-4 rounded-lg"
                    >
                      <div className="mt-1">{item.icon}</div>
                      <div>
                        <h4 className="font-semibold">{item.value}</h4>
                        <p className="text-sm text-gray-600">{item.description}</p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            </motion.div>

            <motion.div
              className="relative"
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="aspect-square rounded-2xl overflow-hidden shadow-xl border-8 border-white">
                <img
                  src="https://images.unsplash.com/photo-1560252829-804f1aedf1be?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
                  alt="GSR Foundation Team"
                  className="w-full h-full object-cover"
                />
              </div>
              <motion.div
                className="absolute -bottom-8 -left-8 bg-white rounded-lg p-6 shadow-lg"
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: 0.4, duration: 0.3 }}
              >
                <p className="text-primary font-bold text-3xl">1.5+</p>
                <p className="text-sm text-gray-600">Years of Service</p>
              </motion.div>
              <motion.div
                className="absolute -top-8 -right-8 bg-white rounded-lg p-6 shadow-lg"
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ delay: 0.5, duration: 0.3 }}
              >
                <p className="text-primary font-bold text-3xl">1000+</p>
                <p className="text-sm text-gray-600">Lives Impacted</p>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-gray-50">
        <div className="container mx-auto">
          <motion.div
            className="text-center max-w-3xl mx-auto mb-16"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="heading-2">Our <span className="text-primary">History</span></h2>
            <p className="subheading">
              The journey of GSR Foundation from its humble beginnings to its current impact across multiple communities around the world.
            </p>
          </motion.div>

          <div className="relative">
            {/* Timeline */}
            <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-primary/20 z-0"></div>

            {/* Milestones */}
            <div className="relative z-10">
              {milestones.map((milestone, index) => (
                <motion.div
                  key={index}
                  className={`flex mb-12 ${index % 2 === 0 ? 'justify-start' : 'justify-end'}`}
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <div className={`w-5/12 ${index % 2 !== 0 && 'order-2'}`}>
                    <div className="bg-white p-6 rounded-xl shadow-md border border-gray-100 transition-all duration-300 hover:-translate-y-1 hover:shadow-lg">
                      <div className="text-lg font-bold text-primary mb-1">{milestone.year}</div>
                      <h3 className="text-xl font-bold mb-2">{milestone.title}</h3>
                      <p className="text-gray-600">{milestone.description}</p>
                    </div>
                  </div>
                  <div className="z-20 flex justify-center items-center">
                    <motion.div
                      className="w-8 h-8 bg-primary rounded-full border-4 border-white"
                      initial={{ scale: 0 }}
                      whileInView={{ scale: 1 }}
                      viewport={{ once: true }}
                      transition={{ type: "spring", stiffness: 300, delay: index * 0.1 + 0.2 }}
                    ></motion.div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-white justify-center align-center">
        <div className="container mx-auto">
          <motion.div
            className="text-center max-w-3xl mx-auto mb-16"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="heading-2">Our <span className="text-primary">Team</span></h2>
            <p className="subheading">
              Meet the dedicated professionals behind GSR Foundation who work tirelessly to create positive change in communities around the world.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 justify-center">
            {team.map((member, index) => (
              <motion.div
                key={index}
                className="bg-white rounded-xl overflow-hidden shadow-md transition-all duration-300 hover:-translate-y-2 hover:shadow-xl"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.4, delay: index * 0.1 }}
              >
                <div className="aspect-[3/4] overflow-hidden">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-full h-full object-cover transition-transform duration-500 hover:scale-105"
                  />
                </div>
                <div className="p-6 text-center"> {/* Added text-center here for center alignment of text */}
                  <h3 className="text-xl font-bold mb-1">{member.name}</h3>
                  <p className="text-primary font-medium mb-3">{member.role}</p>
                  <p className="text-gray-600">{member.bio}</p>
                </div>
              </motion.div>
            ))}
          </div>

          <motion.div
            className="text-center mt-12"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <p className="text-gray-600">
              Our team is supported by a dedicated Board of Directors and numerous volunteers who contribute their time and expertise.
            </p>
          </motion.div>
        </div>
      </section>

      <section className="py-16 bg-primary/5  justify-center">
        <div className="container mx-auto">
          <motion.div
            className="max-w-3xl mx-auto text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="heading-3 mb-4">Join Our Mission</h2>
            <p className="text-gray-700 mb-8">
              If you're passionate about creating positive change and want to be part of our mission, we'd love to hear from you.
              Whether through donations, volunteering, or partnerships, there are many ways to support our work.
            </p>
            <div className="flex flex-wrap justify-center gap-4">
              <motion.a
                href="/donate"
                className="btn-primary inline-block text-white"
                whileHover={{ scale: 1.05 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              >
                Donate Now
              </motion.a>
              <motion.a
                href="/contact"
                className="btn-outline inline-block"
                whileHover={{ scale: 1.05 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              >
                Contact Us
              </motion.a>
            </div>
          </motion.div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default AboutPage;
