import React, { useEffect } from "react";  
import { Toaster } from "./components/ui/toaster";  
import { Toaster as Sonner } from "./components/ui/sonner";  
import { TooltipProvider } from "./components/ui/tooltip";  
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";  
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";  
import Index from "./pages/Index";  
import ProgramsPage from "./pages/ProgramsPage";  
import AboutPage from "./pages/AboutPage";  
import ContactPage from "./pages/ContactPage";  
import PrivacyPage from "./pages/PrivacyPage";  
import TermsPage from "./pages/TermsPage";  
import InfoPage from "./pages/InfoPage";  
import DonatePage from "./pages/DonatePage";  
import NotFound from "./pages/NotFound";  

const queryClient = new QueryClient();  

const ScrollToTop = () => {  
  const { pathname } = useLocation();  

  useEffect(() => {  
    window.scrollTo(0, 0);  
  }, [pathname]);  

  return null;  
};  

const App = () => (  
  <QueryClientProvider client={queryClient}>  
    <TooltipProvider>  
      <Toaster />  
      <Sonner />  
      <BrowserRouter>  
        <ScrollToTop />  
        <Routes>  
          <Route path="/" element={<Index />} />  
          <Route path="/programs" element={<ProgramsPage />} />  
          <Route path="/about" element={<AboutPage />} />  
          <Route path="/contact" element={<ContactPage />} />  
          <Route path="/privacy" element={<PrivacyPage />} />  
          <Route path="/terms" element={<TermsPage />} />  
          <Route path="/info" element={<InfoPage />} />  
          <Route path="/donate" element={<DonatePage />} />  
          {/* ADD ALL CUSTOM ROUTES ABOVE THE CATCH-ALL "*" ROUTE */}  
          <Route path="*" element={<NotFound />} />  
        </Routes>  
      </BrowserRouter>  
    </TooltipProvider>  
  </QueryClientProvider>  
);  

export default App;  