
import React from 'react';
import { Download, FileText, FileImage, FileArchive } from 'lucide-react';
import { Button } from './ui/button';
import { cn } from '../lib/utils';
import { motion } from 'framer-motion';
import { toast } from "sonner";

interface FileDownloadProps {
  fileName: string;
  fileUrl: string;
  fileSize?: string;
  fileType?: 'pdf' | 'image' | 'zip' | 'doc' | 'other';
  variant?: 'default' | 'ghost' | 'link' | 'outline' | 'secondary' | 'destructive';
  className?: string;
  showToast?: boolean;
  displayName?: string;
}

const FileDownload = ({ 
  fileName, 
  fileUrl, 
  fileSize, 
  fileType = 'pdf', 
  variant = 'ghost',
  className,
  showToast = true,
  displayName
}: FileDownloadProps) => {
  
  const getFileIcon = () => {
    switch (fileType) {
      case 'pdf':
        return <FileText className="text-red-500" />;
      case 'image':
        return <FileImage className="text-blue-500" />;
      case 'zip':
        return <FileArchive className="text-yellow-500" />;
      case 'doc':
        return <FileText className="text-blue-600" />;
      default:
        return <FileText className="text-gray-500" />;
    }
  };
  
  const handleDownload = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (showToast) {
      toast.success("Download started", {
        description: `${displayName || fileName} will be downloaded to your device`,
        duration: 3000
      });
    }
    // Let the default anchor behavior handle the actual download
  };

  return (
    <motion.div 
      className={cn("group", className)}
      whileHover={{ scale: 1.01 }}
      transition={{ duration: 0.2 }}
    >
      <Button 
        variant={variant} 
        asChild 
        className={cn("w-full justify-start gap-2 text-left", 
          variant === 'ghost' && "hover:bg-primary/5")}
      >
        <a 
          href={fileUrl}
          download={fileName}
          onClick={handleDownload}
          className="flex items-center"
        >
          {getFileIcon()}
          <div className="flex-1 overflow-hidden text-ellipsis">
            <span className="block truncate">{displayName || fileName}</span>
            {fileSize && <span className="text-xs text-gray-500">{fileSize}</span>}
          </div>
          <Download size={18} className="opacity-70 group-hover:opacity-100 transition-opacity" />
        </a>
      </Button>
    </motion.div>
  );
};

export default FileDownload;
