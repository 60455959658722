
import React, { useState, useEffect } from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import { Button } from '../components/ui/button';
import { 
  Heart, 
  CreditCard, 
  Building, 
  Landmark, 
  Copy, 
  CheckCircle, 
  Share2, 
  Download 
} from 'lucide-react';
import { useToast } from '../hooks/use-toast';
import hdfc from '../assets/hdfc-logo.png';

const DonatePage = () => {
  const { toast } = useToast();
  const [donationAmount, setDonationAmount] = useState("1000");
  const [donorName, setDonorName] = useState("");
  const [donorEmail, setDonorEmail] = useState("");
  const [donorPhone, setDonorPhone] = useState("");
  const [donationType, setDonationType] = useState("oneTime");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCopyAccount = () => {
    navigator.clipboard.writeText("***************");
    setCopied(true);
    toast({
      title: "Account details copied!",
      description: "The account number has been copied to clipboard.",
    });
    
    setTimeout(() => setCopied(false), 3000);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.1,
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navigation />
      
      <section className="pt-32 pb-12 bg-primary text-white">
        <div className="container mx-auto">
          <div className="max-w-3xl">
            <motion.h1 
              className="heading-1"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Make a Donation
            </motion.h1>
            <motion.p 
              className="text-white text-lg mt-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.5 }}
            >
              Your contribution helps us create lasting change in communities worldwide. Choose your preferred donation method below.
            </motion.p>
          </div>
        </div>
      </section>
      
      <section className="py-16 bg-white">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              className="space-y-8"
            >
              <motion.div variants={itemVariants}>
                <h2 className="heading-3 mb-6">Choose Donation Amount</h2>
                
                <div className="grid grid-cols-3 gap-4 mb-6">
                  {["500", "1000", "5000", "10000", "25000", "Custom"].map((amount) => (
                    <Button
                      key={amount}
                      variant={donationAmount === amount ? "default" : "outline"}
                      className={`py-6 ${donationAmount === amount ? 'bg-primary text-white' : 'border-2'}`}
                      onClick={() => setDonationAmount(amount)}
                    >
                      {amount !== "Custom" ? `₹${amount}` : amount}
                    </Button>
                  ))}
                </div>

                {donationAmount === "Custom" && (
                  <div className="mb-6">
                    <label className="block text-sm font-medium mb-2">Enter custom amount (₹)</label>
                    <input
                      type="number"
                      min="100"
                      className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                      placeholder="Enter amount"
                    />
                  </div>
                )}
                
                <div className="mb-6">
                  <h3 className="text-lg font-medium mb-3">Donation Frequency</h3>
                  <div className="flex space-x-4">
                    <Button
                      variant={donationType === "oneTime" ? "default" : "outline"}
                      className={donationType === "oneTime" ? 'bg-primary' : ''}
                      onClick={() => setDonationType("oneTime")}
                    >
                      One-time
                    </Button>
                    <Button
                      variant={donationType === "monthly" ? "default" : "outline"}
                      className={donationType === "monthly" ? 'bg-primary' : ''}
                      onClick={() => setDonationType("monthly")}
                    >
                      Monthly
                    </Button>
                    <Button
                      variant={donationType === "annual" ? "default" : "outline"}
                      className={donationType === "annual" ? 'bg-primary' : ''}
                      onClick={() => setDonationType("annual")}
                    >
                      Annual
                    </Button>
                  </div>
                </div>
              </motion.div>
              
              <motion.div variants={itemVariants} className="space-y-6">
                <h3 className="text-xl font-semibold">Personal Information</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium mb-2">Full Name</label>
                    <input
                      type="text"
                      value={donorName}
                      onChange={(e) => setDonorName(e.target.value)}
                      className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                      placeholder="John Doe"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-2">Email Address</label>
                    <input
                      type="email"
                      value={donorEmail}
                      onChange={(e) => setDonorEmail(e.target.value)}
                      className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                      placeholder="john@example.com"
                      required
                    />
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium mb-2">Phone Number</label>
                  <input
                    type="tel"
                    value={donorPhone}
                    onChange={(e) => setDonorPhone(e.target.value)}
                    className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
                    placeholder="+91 98765 43210"
                  />
                </div>

                <div className="pt-4">
                  <Button className="w-full py-6 text-white text-lg bg-primary hover:bg-primary-dark">
                    Complete Donation <Heart size={18} className="ml-2" />
                  </Button>
                </div>
              </motion.div>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="space-y-8"
            >
              <div className="bg-gray-50 p-8 rounded-xl border border-gray-100 shadow-sm">
                <div className="flex justify-between items-center mb-6">
                  <h3 className="text-xl font-bold">Bank Transfer Details</h3>
                  <img src={hdfc} alt="HDFC Bank" className="h-8" />
                </div>
                
                <div className="space-y-4">
                  <div className="flex justify-between p-3 bg-white rounded-lg">
                    <div>
                      <p className="text-sm text-gray-500">Account Name</p>
                      <p className="font-semibold">GSR Foundation</p>
                    </div>
                    <Building className="text-primary self-center" />
                  </div>
                  
                  <div className="flex justify-between p-3 bg-white rounded-lg">
                    <div>
                      <p className="text-sm text-gray-500">Account Number</p>
                      <p className="font-semibold">*******************</p>
                    </div>
                    <button 
                      onClick={handleCopyAccount}
                      className="text-primary hover:text-primary-dark self-center"
                    >
                      {copied ? <CheckCircle size={20} /> : <Copy size={20} />}
                    </button>
                  </div>
                  
                  <div className="flex justify-between p-3 bg-white rounded-lg">
                    <div>
                      <p className="text-sm text-gray-500">IFSC Code</p>
                      <p className="font-semibold">HDFC0001234</p>
                    </div>
                    <Landmark className="text-primary self-center" />
                  </div>
                  
                  <div className="flex justify-between p-3 bg-white rounded-lg">
                    <div>
                      <p className="text-sm text-gray-500">Bank Branch</p>
                      <p className="font-semibold">Nellore Main Branch</p>
                    </div>
                    <CreditCard className="text-primary self-center" />
                  </div>
                </div>

                <div className="mt-6 text-center">
                  <p className="text-sm text-gray-500 mb-4">After making the transfer, please email your transaction details to:</p>
                  <p className="font-semibold text-primary">donations@gsrfoundation.org</p>
                </div>
              </div>
              
              <div className="bg-primary/10 p-6 rounded-xl">
                <h3 className="text-lg font-semibold mb-4">Other Ways to Support</h3>
                <ul className="space-y-3">
                  <li className="flex items-center gap-3">
                    <CheckCircle size={20} className="text-primary flex-shrink-0" />
                    <span>Monthly giving program for sustained impact</span>
                  </li>
                  <li className="flex items-center gap-3">
                    <CheckCircle size={20} className="text-primary flex-shrink-0" />
                    <span>Corporate matching gift programs</span>
                  </li>
                  <li className="flex items-center gap-3">
                    <CheckCircle size={20} className="text-primary flex-shrink-0" />
                    <span>Legacy giving and planned donations</span>
                  </li>
                  <li className="flex items-center gap-3">
                    <CheckCircle size={20} className="text-primary flex-shrink-0" />
                    <span>In-kind donations of goods and services</span>
                  </li>
                </ul>
                <div className="mt-6 flex flex-wrap gap-2">
                  <Button variant="outline" className="flex items-center gap-2">
                    <Download size={18} />
                    Donation Form
                  </Button>
                  <Button variant="outline" className="flex items-center gap-2">
                    <Share2 size={18} />
                    Share
                  </Button>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto">
          <motion.div 
            className="text-center max-w-3xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="heading-3 mb-4">Your Impact</h2>
            <p className="text-gray-600 mb-12">
              Your generous donation directly supports our programs that create real change in communities around the world.
              Here's how your contribution makes a difference:
            </p>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  amount: "₹1,000",
                  impact: "Provides educational materials for 5 students for a full academic year",
                  image: "https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                },
                {
                  amount: "₹5,000",
                  impact: "Supplies clean water to a rural family for an entire year",
                  image: "https://images.unsplash.com/photo-1565043589221-2e1b96c72a09?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                },
                {
                  amount: "₹10,000",
                  impact: "Funds a healthcare camp serving over 100 people in underserved areas",
                  image: "https://images.unsplash.com/photo-1576091160550-2173dba999ef?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                }
              ].map((item, index) => (
                <motion.div 
                  key={index}
                  className="bg-white rounded-xl overflow-hidden shadow-sm"
                  whileHover={{ y: -5, boxShadow: "0 10px 25px -5px rgba(0, 0, 0, 0.1)" }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="h-40 overflow-hidden">
                    <img 
                      src={item.image} 
                      alt={`Impact of ${item.amount}`}
                      className="w-full h-full object-cover transition-all duration-300 hover:scale-105" 
                    />
                  </div>
                  <div className="p-6">
                    <p className="text-2xl font-bold text-primary">{item.amount}</p>
                    <p className="mt-2 text-gray-600">{item.impact}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default DonatePage;
