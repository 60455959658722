import React, { useState } from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { ArrowRight, Calendar, MapPin, Clock, X, Download, FileImage, ImageIcon, Video, Play } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Dialog,
  DialogContent,
  DialogClose,
  DialogFooter
} from "../components/ui/dialog";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
} from "../components/ui/carousel";
import { AspectRatio } from "../components/ui/aspect-ratio";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Button } from "../components/ui/button";
import { toast } from "sonner";
import FileDownload from '../components/FileDownload';
import VideoPlayer from '../components/VideoPlayer';

import FoodDistribution1 from  "../assets/images/FoodDistribution1.jpeg";
import FoodDistribution2 from "../assets/images/FoodDistribution2.jpeg";
import FoodDistribution3 from  "../assets/images/FoodDistribution3.jpeg";
import FoodDistribution4 from  "../assets/images/FoodDistribution4.jpeg";

import FoodDistributionVideo1 from  "../assets/videos/FoodDistributionVideo1.mp4";
import FoodDistributionVideo2 from "../assets/videos/FoodDistributionVideo2.mp4";
import FoodDistributionVideo3 from  "../assets/videos/FoodDistributionVideo3.mp4";

const ProgramsPage = () => {
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [longPressTimer, setLongPressTimer] = useState(null);
  const [activeMediaFilter, setActiveMediaFilter] = useState('all');

  // Programs data including videos
  const programs = [
    {
      id: 1,
      title: "Food Distribution",
      category: "Food and Hospitality",
      location: "Nellore, Andhra Pradesh",
      date: "July 22, 2024",
      time: "09:00 AM - 02:00 PM",
      media: [
        { type: "image", url: FoodDistribution1 },
        { type: "video", url: FoodDistributionVideo2, thumbnail: FoodDistribution3 },
        { type: "image", url: FoodDistribution2 },
        { type: "video", url:FoodDistributionVideo1, thumbnail: FoodDistribution1 },
        { type: "image", url: FoodDistribution3 },
        { type: "video", url: FoodDistributionVideo3 ,thumbnail: FoodDistribution2 },
        { type: "image", url: FoodDistribution4 }

      ],
      description: "Distributing nutritious meals and essential food supplies to underprivileged children and elderly individuals at Githamayee Ashram to ensure they have access to proper nutrition, regardless of their economic background. We reached over 100 residents, providing food packages and support programs to enhance their well-being.",
      link: "/programs"
    },
    // ... keep existing code for other programs
  ];

  const startLongPress = (media) => {
    const timer = setTimeout(() => {
      handleDownload(media);
    }, 700); // 700ms long press
    
    setLongPressTimer(timer);
  };

  const cancelLongPress = () => {
    if (longPressTimer) {
      clearTimeout(longPressTimer);
      setLongPressTimer(null);
    }
  };

  const handleDownload = (media) => {
    if (media.type === 'video') {
      // Extract filename from URL
      const fileName = media.url.split('/').pop() || 'gsr-foundation-video.mp4';
      
      // Create an anchor element and trigger download
      const link = document.createElement('a');
      link.href = media.url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      toast.success("Video download started", {
        description: "The video will be downloaded to your device",
        duration: 3000
      });
    } else {
      // Handle image download
      const fileName = media.url.split('/').pop() || 'gsr-foundation-image.jpg';
      
      const link = document.createElement('a');
      link.href = media.url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      toast.success("Image download started", {
        description: "The image will be downloaded to your device",
        duration: 3000
      });
    }
  };

  const downloadAllProgramMedia = (program) => {
    if (!program || !program.media || !program.media.length) return;
    
    program.media.forEach((media, index) => {
      // Small delay between downloads to avoid browser issues
      setTimeout(() => {
        const extension = media.type === 'video' ? '.mp4' : '.jpg';
        const fileName = `${program.title.replace(/\s+/g, '_')}_${media.type}_${index+1}${extension}`;
        const link = document.createElement('a');
        link.href = media.url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, index * 500);
    });
    
    toast.success(`Downloading all media from ${program.title}`, {
      description: `${program.media.length} files will be downloaded to your device`,
      duration: 3000
    });
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  const getMediaThumbnail = (media) => {
    if (media.type === 'video') {
      return media.thumbnail || media.url;
    }
    return media.url;
  };

  // Filter media items based on active filter
  const getFilteredMedia = (program) => {
    if (activeMediaFilter === 'all') return program.media;
    return program.media.filter(media => media.type === activeMediaFilter);
  };

  return (
    <div className="min-h-screen">
      <Navigation />
      
      <section className="pt-32 pb-12 bg-primary text-white">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">Our Programs</h1>
            <p className="text-white text-lg">
              Discover the initiatives that are making a real difference in communities worldwide. Our programs focus on creating sustainable impact through education, health, environmental conservation, and community building.
            </p>
          </div>
        </div>
      </section>
      
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {programs.map((program, index) => (
              <motion.div 
                key={program.id}
                variants={cardVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: index * 0.1 }}
                whileHover={{ y: -5, transition: { duration: 0.2 } }}
                className="h-full"
              >
                <Card className="h-full flex flex-col bg-white rounded-xl overflow-hidden shadow-md transition-all duration-300 hover:shadow-xl">
                  <div className="relative">
                    <AspectRatio ratio={16/9}>
                      <Carousel className="w-full">
                        <CarouselContent>
                          {program.media.map((media, i) => (
                            <CarouselItem key={i}>
                              <div 
                                className="cursor-pointer relative h-full"
                                onClick={() => {
                                  setSelectedProgram(program);
                                  setSelectedMedia(media);
                                }}
                                onMouseDown={() => startLongPress(media)}
                                onMouseUp={cancelLongPress}
                                onMouseLeave={cancelLongPress}
                                onTouchStart={() => startLongPress(media)}
                                onTouchEnd={cancelLongPress}
                              >
                                <img 
                                  src={getMediaThumbnail(media)} 
                                  alt={`${program.title} ${i+1}`}
                                  className="w-full h-full object-cover"
                                />
                                {media.type === 'video' && (
                                  <div className="absolute inset-0 flex items-center justify-center bg-black/30">
                                    <div className="rounded-full bg-primary/90 p-2 backdrop-blur-sm">
                                      <Play className="h-5 w-5 text-white" />
                                    </div>
                                  </div>
                                )}
                                <div className="absolute inset-0 bg-black/10 hover:bg-black/20 transition-colors duration-200"></div>
                                <div className="absolute top-2 right-2">
                                  <Button
                                    variant="ghost"
                                    size="icon"
                                    className="h-7 w-7 rounded-full bg-black/50 text-white hover:bg-black/70"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDownload(media);
                                    }}
                                  >
                                    <Download className="h-3 w-3" />
                                  </Button>
                                </div>
                              </div>
                            </CarouselItem>
                          ))}
                        </CarouselContent>
                        <CarouselPrevious className="left-2 opacity-70 hover:opacity-100" />
                        <CarouselNext className="right-2 opacity-70 hover:opacity-100" />
                      </Carousel>
                    </AspectRatio>
                    <div className="absolute top-3 left-3 z-10">
                      <span className="inline-block bg-primary text-white text-xs font-semibold px-2 py-1 rounded">
                        {program.category}
                      </span>
                    </div>
                  </div>
                  
                  <CardHeader className="pb-2">
                    <CardTitle className="text-xl font-bold">{program.title}</CardTitle>
                    <CardDescription className="text-sm flex items-center gap-1">
                      <MapPin className="h-3 w-3" /> {program.location}
                    </CardDescription>
                  </CardHeader>
                  
                  <CardContent className="pb-2 flex-1">
                    <p className="text-gray-600 line-clamp-3">
                      {program.description}
                    </p>
                  </CardContent>
                  
                  <CardFooter className="flex flex-col items-start space-y-3 pt-0">
                    <div className="flex flex-col space-y-1 text-sm text-gray-500 w-full">
                      <div className="flex items-center gap-1">
                        <Calendar className="h-3 w-3" /> 
                        <span>{program.date}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <Clock className="h-3 w-3" /> 
                        <span>{program.time}</span>
                      </div>
                    </div>
                    
                    <div className="flex justify-between w-full">
                      <Button 
                        variant="ghost" 
                        size="sm" 
                        className="text-primary hover:text-primary-dark hover:bg-primary/10 p-0 h-auto"
                        onClick={() => setSelectedProgram(program)}
                      >
                        View details <ArrowRight className="h-4 w-4 ml-1" />
                      </Button>
                      
                      <Button
                        variant="ghost"
                        size="sm"
                        className="text-primary hover:text-primary-dark hover:bg-primary/10 p-0 h-auto"
                        onClick={() => downloadAllProgramMedia(program)}
                      >
                        <Download className="h-4 w-4 mr-1" /> All
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Media Viewer Dialog */}
      <Dialog open={!!selectedMedia} onOpenChange={() => setSelectedMedia(null)}>
        <DialogContent className="max-w-5xl p-0 overflow-auto bg-black/95">
          <div className="relative flex items-center overflow-auto justify-center w-full h-full min-h-[300px] md:min-h-[500px]">
            <AnimatePresence>
              {selectedMedia && selectedMedia.type === 'image' ? (
                <motion.img 
                  key="image"
                  src={selectedMedia.url} 
                  alt="Program image"
                  className="max-h-[80vh] max-w-full object-contain"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              ) : selectedMedia && (
                <VideoPlayer 
                  key="video"
                  src={selectedMedia.url} 
                  poster={selectedMedia.thumbnail}
                  className="max-h-[80vh] w-full max-w-3xl"
                  controls={true}
                  autoplay={true}
                />
              )}
            </AnimatePresence>
            <DialogClose className="absolute top-2 right-2 rounded-full p-2 bg-black/50 text-white hover:bg-black/70 z-50">
              <X className="h-5 w-5" />
            </DialogClose>
            {selectedMedia && (
              <Button 
                variant="ghost"
                size="icon" 
                className="absolute bottom-2 right-2 rounded-full bg-black/50 text-white hover:bg-black/70 z-50"
                onClick={() => handleDownload(selectedMedia)}
              >
                <Download className="h-5 w-5" />
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>

      {/* Program Details Dialog */}
      <Dialog open={!!selectedProgram && !selectedMedia} onOpenChange={() => setSelectedProgram(null)}>
        <DialogContent className="max-w-4xl overflow-y-auto max-h-[90vh]">
          {selectedProgram && (
            <div className="flex flex-col md:flex-row gap-6">
              <div className="md:w-1/2">
                <div className="mb-4 flex items-center gap-2">
                  <Button 
                    variant="outline" 
                    size="sm" 
                    className={`${activeMediaFilter === 'all' ? 'bg-primary text-white' : ''}`}
                    onClick={() => setActiveMediaFilter('all')}
                  >
                    All
                  </Button>
                  <Button 
                    variant="outline" 
                    size="sm"
                    className={`${activeMediaFilter === 'image' ? 'bg-primary text-white' : ''}`}
                    onClick={() => setActiveMediaFilter('image')}
                  >
                    <ImageIcon size={16} className="mr-1" /> Images
                  </Button>
                  <Button 
                    variant="outline" 
                    size="sm"
                    className={`${activeMediaFilter === 'video' ? 'bg-primary text-white' : ''}`}
                    onClick={() => setActiveMediaFilter('video')}
                  >
                    <Video size={16} className="mr-1" /> Videos
                  </Button>
                </div>
                
                <Carousel className="w-full">
                  <CarouselContent>
                    {getFilteredMedia(selectedProgram).map((media, i) => (
                      <CarouselItem key={i}>
                        <AspectRatio ratio={3/2}>
                          <div 
                            className="cursor-pointer relative h-full"
                            onClick={() => setSelectedMedia(media)}
                          >
                            {media.type === 'video' ? (
                              <>
                                <img 
                                  src={media.thumbnail || media.url}
                                  alt={`${selectedProgram.title} video ${i+1}`}
                                  className="w-full h-full object-cover rounded-md"
                                />
                                <div className="absolute inset-0 flex items-center justify-center bg-black/40">
                                  <div className="rounded-full bg-primary p-3">
                                    <Play className="h-6 w-6 text-white" />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <img 
                                src={media.url} 
                                alt={`${selectedProgram.title} ${i+1}`}
                                className="w-full h-full object-cover rounded-md"
                              />
                            )}
                            <Button
                              variant="ghost"
                              size="icon"
                              className="absolute top-2 right-2 h-8 w-8 rounded-full bg-black/50 text-white hover:bg-black/70"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDownload(media);
                              }}
                            >
                              <Download className="h-4 w-4" />
                            </Button>
                          </div>
                        </AspectRatio>
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                  <CarouselPrevious />
                  <CarouselNext />
                </Carousel>
                
                <div className="mt-4 flex justify-between">
                  <div className="text-sm text-gray-500">
                    {getFilteredMedia(selectedProgram).length} {activeMediaFilter === 'all' ? 'items' : activeMediaFilter + 's'}
                  </div>
                  <Button 
                    variant="outline" 
                    size="sm"
                    onClick={() => downloadAllProgramMedia(selectedProgram)}
                    className="flex gap-2 text-primary"
                  >
                    <Download size={18} />
                    Download All Media Files
                  </Button>
                </div>
                
                <div className="grid grid-cols-4 gap-2 mt-4">
                  {getFilteredMedia(selectedProgram).slice(0, 8).map((media, i) => (
                    <div 
                      key={i} 
                      className="relative cursor-pointer rounded-md overflow-hidden aspect-square"
                      onClick={() => setSelectedMedia(media)}
                    >
                      <img 
                        src={getMediaThumbnail(media)}
                        alt={`${selectedProgram.title} thumbnail ${i+1}`}
                        className="w-full h-full object-cover"
                      />
                      {media.type === 'video' && (
                        <div className="absolute inset-0 flex items-center justify-center bg-black/30">
                          <Play className="h-4 w-4 text-white" />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="md:w-1/2">
                <h2 className="text-2xl font-bold mb-2">{selectedProgram.title}</h2>
                <div className="mb-4">
                  <span className="inline-block bg-primary/10 text-primary text-xs font-semibold px-2 py-1 rounded">
                    {selectedProgram.category}
                  </span>
                </div>
                <div className="space-y-4">
                  <div className="flex items-center gap-2 text-gray-600">
                    <MapPin className="h-4 w-4 text-primary" /> 
                    <span>{selectedProgram.location}</span>
                  </div>
                  <div className="flex items-center gap-2 text-gray-600">
                    <Calendar className="h-4 w-4 text-primary" /> 
                    <span>{selectedProgram.date}</span>
                  </div>
                  <div className="flex items-center gap-2 text-gray-600">
                    <Clock className="h-4 w-4 text-primary" /> 
                    <span>{selectedProgram.time}</span>
                  </div>
                </div>
                <div className="mt-4">
                  <h3 className="text-lg font-semibold mb-2">About this program</h3>
                  <p className="text-gray-600">{selectedProgram.description}</p>
                </div>
                <div className="mt-6 flex flex-col sm:flex-row gap-3">
                  <Button
                    className="flex-1"
                    onClick={() => {
                      // In a real app, this would navigate to the full program page
                      window.location.href = "/programs"
                    }}
                  >
                    Learn More
                  </Button>
                  <Button
                    variant="outline"
                    className="flex-1"
                    onClick={() => {
                      // Generate a PDF report (dummy functionality)
                      const pdfUrl = `/downloads/${selectedProgram.title.replace(/\s+/g, '_')}_Report.pdf`;
                      const link = document.createElement('a');
                      link.href = pdfUrl;
                      link.download = `${selectedProgram.title} Report.pdf`;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                      
                      toast.success("Program report downloading", {
                        description: `${selectedProgram.title} report will be downloaded to your device`,
                        duration: 3000
                      });
                    }}
                  >
                    Download Report
                  </Button>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
      
      <Footer />
    </div>
  );
};

export default ProgramsPage;