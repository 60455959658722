
import React from 'react';
import { motion } from 'framer-motion';
import Navigation from '../components/Navigation';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Programs from '../components/Programs';
import Testimonials from '../components/Testimonials';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

const Index = () => {
  const pageVariants = {
    initial: { 
      opacity: 0 
    },
    animate: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.4,
        delayChildren: 0.2,
        ease: "easeOut",
        duration: 0.6
      } 
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.4
      }
    }
  };
  
  const sectionVariants = {
    initial: { 
      opacity: 0, 
      y: 30 
    },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.6, 0.05, 0.01, 0.9]
      }
    }
  };
  
  return (
    <motion.div 
      className="relative min-h-screen overflow-hidden"
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageVariants}
    >
      <Navigation />
      
      <motion.div 
        variants={sectionVariants} 
        className="pt-16"
      >
        <Hero />
      </motion.div>
      
      <motion.div 
        variants={sectionVariants}
        viewport={{ once: true, margin: "-100px" }}
        whileInView="animate"
        initial="initial"
      >
        <Features />
      </motion.div>
      
      <motion.div 
        variants={sectionVariants}
        viewport={{ once: true, margin: "-100px" }}
        whileInView="animate"
        initial="initial"
      >
        <Programs />
      </motion.div>
      
      <motion.div 
        variants={sectionVariants}
        viewport={{ once: true, margin: "-100px" }}
        whileInView="animate"
        initial="initial"
      >
        <Testimonials />
      </motion.div>
      
      <motion.div 
        variants={sectionVariants}
        viewport={{ once: true, margin: "-100px" }}
        whileInView="animate"
        initial="initial"
      >
        <Contact />
      </motion.div>
      
      <Footer />
      
      {/* Enhanced decorative elements with more animation */}
      <div className="hidden md:block fixed -top-24 -right-24 w-96 h-96 bg-primary/10 rounded-full filter blur-3xl z-0 animate-pulse" />
      <div className="hidden md:block fixed top-1/2 -left-36 w-64 h-64 bg-secondary/10 rounded-full filter blur-3xl z-0 animate-pulse" />
      <div className="hidden md:block fixed bottom-1/3 right-1/4 w-48 h-48 bg-primary/5 rounded-full filter blur-2xl z-0 animate-pulse" 
        style={{ animationDuration: '8s' }}
      />
    </motion.div>
  );
};

export default Index;
