
import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X, ChevronDown, Heart } from 'lucide-react';
import { Button } from './ui/button';

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  // Handle scroll effect for the navigation
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const navLinks = [
    { name: 'Home', path: '/' },
    { name: 'Programs', path: '/programs' },
    { name: 'About Us', path: '/about' },
    { name: 'Contact', path: '/contact' },
    { name: 'Info', path: '/info' },
  ];

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <header className={`fixed w-full z-30 transition-all duration-300 ${scrolled ? 'bg-white/95 shadow-md backdrop-blur-sm' : 'bg-white/95 shadow-md backdrop-blur-sm'}`}>
      <div className="container mx-auto px-4 md:px-6">
        <nav className="flex justify-between items-center py-4">
          {/* Logo */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Link to="/" className="flex items-center space-x-2">
              <div className="text-2xl font-bold text-primary">GSR 
                <span>{' '}</span>
                <span className='text-gray-700'>Foundation</span></div>
            </Link>
          </motion.div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            {navLinks.map((link, index) => (
              <motion.div
                key={link.name}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1, duration: 0.3 }}
              >
                <NavLink
                  to={link.path}
                  className={`px-0 text-sm py-1 mx-1 my-1 transition-all duration-200 ${isActive(link.path)
                    ? 'text-primary font-medium border-b-2 border-primary'
                    : 'text-gray-700 hover:bg-gray-50 rounded-md '
                    }`}
                >
                  {link.name}
                </NavLink>
              </motion.div>
            ))}
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.4, duration: 0.3 }}
            >
              <Link to="/donate">
                <Button className="bg-primary text-white hover:bg-primary-dark flex items-center gap-2 rounded-md px-6">
                  Donate <Heart size={16} className="animate-pulse" />
                </Button>
              </Link>
            </motion.div>
          </div>

          {/* Mobile Navigation Toggle */}
          <motion.div
            className="md:hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <button
              onClick={toggleMenu}
              aria-label="Toggle Menu"
              className="p-1"
            >
              {isMenuOpen ?
                <X className="h-6 w-6 text-gray-700" /> :
                <Menu className="h-6 w-6 text-gray-700" />
              }
            </button>
          </motion.div>
        </nav>

        {/* Mobile Navigation Menu */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              className="md:hidden bg-white rounded-lg shadow-lg overflow-hidden"
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="flex flex-col py-4 space-y-1">
                {navLinks.map((link) => (
                  <Link
                    key={link.name}
                    to={link.path}
                    className={`inline text-center py-2 text-sm mx-1 my-1 transition-all duration-200 ${isActive(link.path)  
                      ? 'text-primary font-medium border-b-2 border-primary'  
                      : 'text-gray-700 hover:bg-gray-50 rounded-md'  
                    }`}  
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {link.name}
                  </Link>
                ))}
                <div className="pt-2 px-4 mt-2">
                  <Link to="/donate" onClick={() => setIsMenuOpen(false)}>
                    <Button className="bg-primary text-white hover:bg-primary-dark w-full flex items-center justify-center gap-2 rounded-md">
                      Donate <Heart size={16} className="animate-pulse" />
                    </Button>
                  </Link>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </header>
  );
};

export default Navigation;
