import React, { useState } from 'react';
import { Button } from './ui/button';
import { ArrowRight, Calendar, MapPin, Users, Play, Download, ImageIcon, Video, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Dialog, DialogContent, DialogClose } from "./ui/dialog";
import VideoPlayer from './VideoPlayer';
import { toast } from "sonner";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle
} from "./ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
} from "./ui/carousel";

import FoodDistribution1 from  "../assets/images/FoodDistribution1.jpeg";
import FoodDistribution2 from "../assets/images/FoodDistribution2.jpeg";
import FoodDistribution3 from  "../assets/images/FoodDistribution3.jpeg";
import FoodDistribution4 from  "../assets/images/FoodDistribution4.jpeg";

import FoodDistributionVideo1 from  "../assets/videos/FoodDistributionVideo1.mp4";
import FoodDistributionVideo2 from "../assets/videos/FoodDistributionVideo2.mp4";
import FoodDistributionVideo3 from  "../assets/videos/FoodDistributionVideo3.mp4";


const Programs = () => {
  const [selectedMedia, setSelectedMedia] = useState<{type: string, url: string, thumbnail?: string} | null>(null);
  
  const programs :any[] = [
   {
        id: 1,
        title: "Food Distribution",
        category: "Food and Hospitality",
        location: "Nellore, Andhra Pradesh",
        date: "July 22, 2024",
        time: "09:00 AM - 02:00 PM",
        media: [
          { type: "image", url: FoodDistribution1 },
          { type: "video", url: FoodDistributionVideo2, thumbnail: FoodDistribution3 },
          { type: "image", url: FoodDistribution2 },
          { type: "video", url:FoodDistributionVideo1, thumbnail: FoodDistribution1 },
          { type: "image", url: FoodDistribution3 },
          { type: "video", url: FoodDistributionVideo3 ,thumbnail: FoodDistribution2 },
          { type: "image", url: FoodDistribution4 }
  
        ],
        description: "Distributing nutritious meals and essential food supplies to underprivileged children and elderly individuals at Githamayee Ashram to ensure they have access to proper nutrition, regardless of their economic background. We reached over 100 residents, providing food packages and support programs to enhance their well-being.",
        link: "/programs"
      },
      // ... keep existing code for other programs
    ];
  
    // },
    // {
    //   title: "Clean Water Initiative",
    //   category: "Health",
    //   location: "Rural Communities",
    //   date: "March 22, 2024",
    //   participants: "500+ Families",
    //   media: [
    //     { type: "image", url: "https://images.unsplash.com/photo-1583146200895-2fdb7e1c7ec5?q=80&w=1171&auto=format&fit=crop" },
    //     { type: "video", url: "https://player.vimeo.com/external/368484050.sd.mp4?s=32bac7e5bc063393d79b1b25b3d276c2b66cae2f&profile_id=164&oauth2_token_id=57447761", thumbnail: "https://images.unsplash.com/photo-1617512310054-e2f3635dd047?q=80&w=1171&auto=format&fit=crop" },
    //     { type: "image", url: "https://images.unsplash.com/photo-1606041957126-72f0c98d9b00?q=80&w=1173&auto=format&fit=crop" }
    //   ],
    //   description: "Ensuring access to clean drinking water in rural communities through sustainable solutions and community participation.",
    //   link: "/programs/clean-water"
    // },
    // {
    //   title: "Women Empowerment",
    //   category: "Community",
    //   location: "Urban Centers",
    //   date: "April 10, 2024",
    //   participants: "150+ Women",
    //   media: [
    //     { type: "image", url: "https://images.unsplash.com/photo-1607748862156-7c548e7e98f4?q=80&w=1170&auto=format&fit=crop" },
    //     { type: "video", url: "https://player.vimeo.com/external/432005970.sd.mp4?s=567c52183d076140db262beb4bff942d5a5c4925&profile_id=164&oauth2_token_id=57447761", thumbnail: "https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?q=80&w=1170&auto=format&fit=crop" },
    //     { type: "image", url: "https://images.unsplash.com/photo-1587554801471-37976a256db0?q=80&w=1170&auto=format&fit=crop" }
    //   ],
    //   description: "Supporting women entrepreneurs with training, resources, and mentorship to help them establish sustainable businesses.",
    //   link: "/programs/women-empowerment"
    // }
 // ];

  const handleDownload = (media) => {
    const fileName = media.type === 'video' 
      ? (media.url.split('/').pop() || 'program-video.mp4')
      : (media.url.split('/').pop() || 'program-image.jpg');
      
    const link = document.createElement('a');
    link.href = media.url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    toast.success(`${media.type.charAt(0).toUpperCase() + media.type.slice(1)} download started`, {
      description: `The ${media.type} will be downloaded to your device`,
      duration: 3000
    });
  };

  const downloadAllMedia = (program) => {
    if (!program || !program.media) return;
    
    program.media.forEach((media, index) => {
      setTimeout(() => {
        const fileName = media.type === 'video'
          ? `${program.title.replace(/\s+/g, '_')}_video_${index}.mp4`
          : `${program.title.replace(/\s+/g, '_')}_image_${index}.jpg`;
          
        const link = document.createElement('a');
        link.href = media.url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, index * 500);
    });
    
    toast.success(`Downloading all media from ${program.title}`, {
      description: `${program.media.length} files will be downloaded to your device`,
      duration: 3000
    });
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.25, 0.1, 0.25, 1]
      }
    }
  };

  return (
    <section className="section bg-gradient-light py-24">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.7 }}
          className="flex flex-col md:flex-row md:items-end md:justify-between mb-16"
        >
          <div>
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Our <span className="text-primary">Programs</span></h2>
            <p className="text-gray-600 max-w-2xl text-balance">
              Discover how our initiatives are making a real difference in communities around the world
              through education, health, and empowerment.
            </p>
          </div>
          <div className="mt-6 md:mt-0">
            <Link to="/programs">
              <Button className="flex items-center gap-2 rounded-full" variant="outline">
                View All Programs
                <ArrowRight className="h-4 w-4" />
              </Button>
            </Link>
          </div>
        </motion.div>
        
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-3 gap-8"
          variants={container}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, margin: "-100px" }}
        >
          {programs.map((program, index) => (
            <motion.div 
              key={index}
              variants={item}
              whileHover={{ y: -5, transition: { duration: 0.2 } }}
              className="h-full"
            >
              <Card className="h-full flex flex-col bg-white/80 backdrop-blur-sm rounded-xl overflow-hidden shadow-lg border-0">
                <div className="relative h-52">
                  <Carousel className="w-full h-full">
                    <CarouselContent className="h-full">
                      {program.media.map((media, i) => (
                        <CarouselItem key={i} className="h-full">
                          <div 
                            className="relative h-full cursor-pointer"
                            onClick={() => setSelectedMedia(media)}
                          >
                            {media.type === 'video' ? (
                              <>
                                <img 
                                  src={media.thumbnail} 
                                  alt={`${program.title} video ${i+1}`} 
                                  className="w-full h-full object-cover"
                                />
                                <div className="absolute inset-0 bg-black/40 flex items-center justify-center">
                                  <div className="rounded-full bg-primary/90 p-2 backdrop-blur-sm">
                                    <Play className="h-8 w-8 text-white" fill="white" />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <img 
                                src={media.url} 
                                alt={`${program.title} image ${i+1}`} 
                                className="w-full h-full object-cover transition-transform duration-700 hover:scale-110"
                              />
                            )}
                          </div>
                        </CarouselItem>
                      ))}
                    </CarouselContent>
                    <CarouselPrevious className="left-2 h-8 w-8 opacity-70 hover:opacity-100" />
                    <CarouselNext className="right-2 h-8 w-8 opacity-70 hover:opacity-100" />
                  </Carousel>
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent pointer-events-none"></div>
                  <div className="absolute top-3 left-3 z-10">
                    <span className="inline-block bg-primary text-white text-xs font-semibold px-3 py-1 rounded-full">
                      {program.category}
                    </span>
                  </div>
                  <div className="absolute bottom-3 left-3 right-3 flex justify-between items-center">
                    <h3 className="text-white font-bold text-xl">{program.title}</h3>
                    <Button 
                      variant="ghost" 
                      size="icon"
                      className="h-8 w-8 rounded-full bg-black/50 text-white hover:bg-black/70"
                      onClick={(e) => {
                        e.stopPropagation();
                        downloadAllMedia(program);
                      }}
                    >
                      <Download className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
                <CardHeader className="pb-2">
                  <CardTitle className="text-xl font-bold sr-only">{program.title}</CardTitle>
                </CardHeader>
                <CardContent className="pb-2 flex-1">
                  <p className="text-gray-600 mb-4">{program.description}</p>
                  <div className="flex flex-col space-y-2 text-sm text-gray-500">
                    <div className="flex items-center gap-2">
                      <MapPin className="h-4 w-4 text-primary" /> 
                      <span>{program.location}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Calendar className="h-4 w-4 text-primary" /> 
                      <span>{program.date}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Users className="h-4 w-4 text-primary" /> 
                      <span>{program.participants}</span>
                    </div>
                  </div>
                </CardContent>
                <CardFooter className="pt-0">
                  <Link 
                    to={program.link} 
                    className="inline-flex items-center text-primary font-medium hover:underline group"
                  >
                    Learn more 
                    <ArrowRight className="h-4 w-4 ml-1 transition-transform group-hover:translate-x-1" />
                  </Link>
                </CardFooter>
              </Card>
            </motion.div>
          ))}
        </motion.div>
      </div>

      {/* Media Viewer Dialog */}
      <Dialog open={!!selectedMedia} onOpenChange={() => setSelectedMedia(null)}>
        <DialogContent className="max-w-4xl p-0 overflow-y-auto bg-black/95">
          <div className="relative flex items-center overflow-y-auto justify-center w-full h-full min-h-[300px] md:min-h-[500px]">
            {selectedMedia?.type === 'image' ? (
              <motion.img 
                key="image"
                src={selectedMedia.url} 
                alt="Program image"
                className="max-h-[80vh] max-w-full object-contain"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              />
            ) : selectedMedia?.type === 'video' && (
              <VideoPlayer 
                key="video"
                src={selectedMedia.url} 
                poster={selectedMedia.thumbnail}
                className="max-h-[80vh] w-full max-w-3xl"
                controls={true}
                autoplay={true}
              />
            )}
            <DialogClose className="absolute top-2 right-2 rounded-full p-2 bg-black/50 text-white hover:bg-black/70 z-50">
              <X className="h-5 w-5" />
            </DialogClose>
            <Button 
              variant="ghost"
              size="icon" 
              className="absolute bottom-2 right-2 rounded-full bg-black/50 text-white hover:bg-black/70 z-50"
              onClick={() => selectedMedia && handleDownload(selectedMedia)}
            >
              <Download className="h-5 w-5" />
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default Programs;