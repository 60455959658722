
import React from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

const PrivacyPage = () => {
  return (
    <div className="min-h-screen">
      <Navigation />
      
      <section className="pt-32 pb-12 bg-gray-100">
        <div className="container mx-auto">
          <div className="max-w-3xl">
            <h1 className="heading-2">Privacy Policy</h1>
            <p className="text-gray-600 text-lg mt-4">
              Last updated: {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
            </p>
          </div>
        </div>
      </section>
      
      <section className="py-16">
        <div className="container mx-auto">
          <div className="max-w-4xl mx-auto prose prose-lg">
            <p>
              GSR Foundation ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by GSR Foundation.
            </p>
            
            <h2>Information We Collect</h2>
            <p>
              We collect information that you provide directly to us, such as when you make a donation, register for an event, sign up for our newsletter, or contact us with questions. This information may include your name, email address, postal address, phone number, and payment information.
            </p>
            
            <h2>How We Use Your Information</h2>
            <p>
              We may use the information we collect from you for various purposes, including to:
            </p>
            <ul>
              <li>Process donations and provide receipts</li>
              <li>Respond to your comments, questions, and requests</li>
              <li>Send you technical notices, updates, security alerts, and support messages</li>
              <li>Provide news and information about our programs, events, and activities</li>
              <li>Monitor and analyze trends, usage, and activities in connection with our website</li>
            </ul>
            
            <h2>Sharing of Information</h2>
            <p>
              We may share your personal information with third-party service providers who perform services on our behalf, such as payment processing and data analysis. We may also share information as required by law or to protect the rights and safety of our organization, supporters, and others.
            </p>
            
            <h2>Your Choices</h2>
            <p>
              You may opt out of receiving promotional emails from us by following the instructions in those emails. You may also request that we update, correct, or delete your personal information by contacting us at privacy@gsrfoundation.org.
            </p>
            
            <h2>Security</h2>
            <p>
              We take reasonable measures to help protect information about you from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.
            </p>
            
            <h2>Cookies</h2>
            <p>
              We use cookies and similar technologies to track visitor activity on our website, collect demographic information, and improve your experience. You can control cookies through your browser settings and other tools.
            </p>
            
            <h2>Changes to this Policy</h2>
            <p>
              We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice.
            </p>
            
            <h2>Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please contact us at:
            </p>
            <address>
              GSR Foundation<br />
              123 Foundation Way<br />
              San Francisco, CA 94103<br />
              privacy@gsrfoundation.org<br />
              (555) 123-4567
            </address>
          </div>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default PrivacyPage;
